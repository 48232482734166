import React from 'react';
import type {CarouselProps} from 'react-responsive-carousel/lib/ts/components/Carousel/types';

export const CustomCarousel: React.FC<Partial<CarouselProps>> = (props) => {

  if (process.browser) {
    const {Carousel} = require('react-responsive-carousel');

    return <Carousel {...props}>{props.children}</Carousel>;
  }

  return <></>
};